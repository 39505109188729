<template>
  <static-fullscreen-card @sendHeight="setH($event)" v-if="m">
    <template v-slot:header>11</template>

    <template v-slot:actions>
      <v-btn color="primary" @click="fetchData()"><btn-title icon="fas fa-sync-alt">Обновить</btn-title> </v-btn>
    </template>
    <portal to="v-main">
      <edit-dialog v-if="showEditDialog" v-model="showEditDialog" :idShow="idEdit" @close="itemShowClose($event)" />
    </portal>
    <v-data-table
      :headers="headers"
      :items="items"
      class="elevation-1"
      :height="cardHeight - 35"
      hide-default-footer
      fixed-header
      disable-sort
      disable-pagination
      disable-filtering
      @click:row="onClickRow"
    >
      <template v-slot:item.createdon="{ item }"> <a-view-table-datetime :value="item.createdon" :model="model.find((m) => m.name == 'createdon')" /> </template>
      <template v-slot:item.createdby_id="{ item }"> <a-view-table-select :value="item.createdby_id" :model="model.find((m) => m.name == 'createdby_id')" /> </template>
    </v-data-table>
  </static-fullscreen-card>
</template>

<script>
import { getAccess, doubleClickDetect, keyDetect, genModel } from "@/components/mixings";
export default {
  mixins: [getAccess, doubleClickDetect, keyDetect, genModel],
  components: {
    editDialog: () => import("./views/myOfferView.vue"),
  },
  data() {
    return {
      idEdit: 0,
      cardHeight: 40,
      showEditDialog: false,
      m: this.$store.getters["config/get"].models.myOffer,
      type: "myOffer",
      defaults: {
        sort: { key: "id", order: "DESC" },
        filters: this.getAccess("myOffer.viewAll") ? {} : { createdby_id: this.$root.profile.id },
        paramName: "myOffer",
      },
      items: [],
    };
  },
  computed: {
    url() {
      return this.m.url;
    },
    model() {
      let res = this.getModelList(this.m);
      if (this.$root.profile.role === 1000) res.find((r) => r.name == "createdby_id").hidden = false;

      return res;
    },
    headers() {
      let res = this.model
        .filter((m) => !m.hidden)
        .map((m) => {
          return { text: m.title, value: m.name, class: m.class, width: m.width };
        });
      return res;
    },
  },
  created() {
    this.$root.title = "Мои предложения";
    this.fetchData();
  },

  methods: {
    test() {
      let o1 = { id: 1, name: "name o1", text: "text o1" };
      let o2 = { id: 3, name2: "name o2", text: "text o2" };
      console.log(Object.assign({}, o1, o2));
    },
    setH(e) {
      console.log("setH", e);
      this.cardHeight = e;
    },
    itemShowClose() {
      this.showEditDialog = false;
      this.loading = true;
      //this.$refs.table.updateRowData(this.idEdit);
      this.fetchData();
      this.loading = !true;
      this.idEdit = 0;
    },
    refreshTable() {
      this.$refs.table.updateData();
    },
    onClickRow(d) {
      this.showEditDialogFun(d.id);
      return;
      this.showEditDialogFun(d.row.id);
    },
    showEditDialogFun(id) {
      this.idEdit = id;
      this.showEditDialog = true;
    },
    async fetchData() {
      this.loading = true;
      let where = this.where || this.defaults?.where || {};
      let filters = Object.assign({}, this.defaults.filters, where);
      //let filters = Object.assign({}, this.filters);
      let params = {
        sort: JSON.stringify({ key: "createdon", order: "desc" }),
        filters,
        pager: { limit: 50 },
      };

      let response = await this.$axios.get(this.url, { params });
      let data;
      if ((data = response.data.data)) {
        this.items = data;
      }
      this.loading = false;
    },
  },
};
</script>
